<template>
  <v-container fluid>
		<UnsealDialog :dialog="unseal_dialog" :backendID="unseal_dialog_serial" :backendLabel="unseal_dialog_label" @input="unsealDialogClosed" />


  <h3 class="mb-2 mt-3" >Tokens</h3> 
  <v-simple-table
		v-if="ca_status"
		>
    <template v-slot:default>
			<thead>
				<tr>
					<th class="text-left">Serial</th>
					<th class="text-left">Label</th>
					<th class="text-left">Needs pin</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="token in (ca_status || {}).tokens" 
					:key="token.serial" 
					:style="{color: (token.needs_pin ? 'red' : '')}" 
					@click="token.needs_pin ? unsealToken(token) : ()=>{}"
					>
					<td>{{ token.serial }}</td>
					<td>{{ token.label }}</td>
					<td>{{ token.needs_pin }} <v-btn v-if="token.needs_pin" color="primary" small>unseal</v-btn></td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
	<v-progress-circular 
		v-else 
		class="ml-3" 
		:size="20" :width="2" 
    color="primary" 
		indeterminate 
	/>

    <h3 class="mt-5">
      CA Certificates
    </h3>

    <v-simple-table
      dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">CN</th>
            <th class="text-left">SAN DNS</th>
            <th class="text-left">OIDC Roles</th>
            <th class="text-left">can sign</th>
            <th class="text-left">expires</th>
            <th class="text-left">download</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cert in ca_certs" :key="cert.serial">
            <td>
                <b>{{ cert.subject_cn }}</b>
            </td>
            <td>{{ cert.san_dns ? cert.san_dns.join(", ") : '' }}</td>
            <td>{{ cert.config ? cert.config.oidc_required_roles : '' }}</td>
            <td>{{ cert.can_sign }}</td>
            <td>{{ cert.not_valid_after }}</td>
            <td>  
              <a :href="'/api/v1/certs/by_sn/' + cert.serial">
                pem
              </a>
              |
              <a :href="'/api/v1/certs/by_sn/' + cert.serial + '?format=p12'">
                p12
              </a>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

	<h3 class="mb-5 mt-6" >Status</h3> 

  <pre>{{ unseal_dialog }}</pre>

  <pre style="font-size: 14px">CA status: {{ca_status ? JSON.stringify(ca_status, null, 1) : "loading..."}} </pre>
  <v-divider class="mt-5 mb-5" />
  <pre style="font-size: 14px">certs: {{ca_certs ? JSON.stringify(ca_certs, null, 1) : "loading ..."}} </pre>
  <v-divider class="mt-5 mb-5" />
  <pre style="font-size: 14px">OpenID ID Token: {{JSON.stringify(oid_token, null, 1)}} </pre>
  <pre style="font-size: 14px">OpenID Access Token: {{JSON.stringify(access_token, null, 1)}} </pre>
  </v-container>

</template>

<script>
import UnsealDialog from '../components/UnsealDialog';
export default {
  data() {
    return {
      ca_status: null,
      ca_certs: null,
      oid_token: {},
      access_token: {},
			unseal_dialog: false,
			unseal_dialog_serial: null,
			unseal_dialog_label: null,
    }
  },
	components: {
    UnsealDialog
  },
  mounted: function() {
    this.refreshStatus();
    this.refreshCerts();
    setTimeout(() => {
      if(window.keycloak) {
        this.oid_token = window.keycloak.idTokenParsed;
        this.access_token = window.keycloak.tokenParsed;
      }
    }, 1000);
  },
	methods: {
    refreshStatus: function() {
      this.ca_status = null;
      window.api.get("/ca/status")
      .then(res => {
        this.ca_status = res.data;
      })
      .catch(err => {
        this.ca_status = { error: JSON.stringify(err)}
      })
    },
    refreshCerts: function() {
      this.ca_certs = null;
      window.api.get("/certs")
      .then(res => {
        this.ca_certs = res.data;
      })
      .catch(err => {
        this.ca_certs = { error: JSON.stringify(err)}
      })
    },
		unsealToken: function(token) {
			//alert("unseal: " + token);
			this.unseal_dialog_label = token.label;
			this.unseal_dialog_serial = token.serial;
			this.unseal_dialog = true;
		},
		unsealDialogClosed: function() {
      this.refreshStatus();
		},
	},
}
</script>
