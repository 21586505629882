<template>
    <v-dialog
      v-model="dialog"
			persistent
      width="500"
    >

      <div>
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
                >
            Unseal Backend "{{backendID}}"
            </v-card-title>

            <v-card-text class="mt-6">
								Enter Security Backend/Module PIN. <br><br>Make sure you're entering this from a reasonably secure device.

								<form @submit.prevent="unseal">
                <v-text-field
									v-model="pin"
									class="mt-4 mb-4"
                  label="PIN"
                  hint=""
                  persistent-hint
                  required
									type="password"
									autofocus
									autocomplete="one-time-code"
                ></v-text-field>
								</form>


            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary"
                text
								:disabled="inProgress"
                @click="dialog = false"
            >
                Close
            </v-btn>

            <v-btn
                color="primary"
                text
								:disabled="inProgress || this.pin == null || this.pin.length < 3"
                @click="unseal"
            >
                Unseal
								<v-progress-circular 
									v-if="inProgress" 
									class="ml-3 mx-auto" 
									:size="14" :width="2" 
									indeterminate 
									color="primary"
								/>
            </v-btn>

            </v-card-actions>
        </v-card>
       </div>
    </v-dialog>
</template>

<script>
export default {
    data () {
      return {
				inProgress: false,
				pin: null,
        text: ""
      }
    },
    props: ['dialog', 'backendID', 'backendLabel'],
    mounted: function() {
      console.log("dialog mounted!");
    },
    methods: {
			unseal: function() {
				this.inProgress = true;
        window.api.post("/ca/unseal", {pin: this.pin, 'backend': this.$props.backendID })
        .then(response => {
          console.log("res: ", response);
					this.inProgress = false;
					this.dialog = false;
          this.$emit('input')
				})
				.catch(err => {
					alert(JSON.stringify(err))
          this.dialog = false;
				})
			},
      closed: function() {
        alert('closed2');
      }
    },
}
</script>
